import Grid from "@material-ui/core/Grid"
import React, { useEffect } from "react"
import Tilt from "react-tilt"
import Layout from "../components/layout"
import SEO from "../components/seo"
import positions from "../positions"
import {
  CandidatiCard,
  HeartSymbol,
  createTargetMailto,
} from "../templates/detail"
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import "../styles/style.css"
import { formatMs } from "@material-ui/core/styles"

const hPadding = 24

const IndexPage = () => {
  const availablePositions = positions.filter(pos => !pos.closed)

  useEffect(() => {
    if (window.location.href.endsWith("?hiring")) {
      const openPositions = positions.filter(p => !p.closed)
      if (openPositions.length === 1) {
        window.location.replace("/" + openPositions[0].slug)
      } else {
        window.history.replaceState({}, undefined, "/")
      }
    }
  }, [])

  return (
    <Layout>
      <SEO title="Mabiloft Jobs" description="Vuoi unirti al team?" />

      <Grid
        container
        direction="row"
        justify="space-between"
        className="page-container"
      >
        <Grid item xs>
          <div
            className="page-container-top-padding"
            style={{
              // height: "13vw",
              // zIndex: 2,
              position: "relative",
              whiteSpace: "nowrap",
              minHeight: 300,
              paddingBottom: 250,
              marginBottom: -250,
            }}
          >
            <div
              style={{
                lineHeight: "13vw",
                fontSize: "4vw",
                fontWeight: 700,
                color: "white",
                zIndex: 1,
                position: "relative",
              }}
              children="Posizioni aperte"
            />

            <img
              style={{
                position: "absolute",
                left: -2000,
                right: -2000,
                top: 0,
                height: "100%",
                maxWidth: "none",
                margin: "auto",
                pointerEvents: "none",
              }}
              src={require("../images/mabiloft behance cover.png")}
            />
          </div>

          {availablePositions.map((target, index) => (
            <PositionCard
              position={target}
              key={index}
              cta="Scopri di più"
              href={"/" + target.slug}
            />
          ))}

          <div style={{ height: 200 }} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default IndexPage

const Paragraph = props => (
  <p
    {...props}
    style={{
      // marginTop: 50,
      // marginBottom: 50,
      color: "#1B203C",
      fontSize: 18,
      // fontSize: 20,s
      lineHeight: 1.33,
      letterSpacing: 0.5,
      // maxWidth: 600,
      // maxWidth: 700,
      padding: "0 " + hPadding + "px",
      whiteSpace: "pre-line",
      ...(props.style || {}),
    }}
  />
)

const DottedParagraph = props => (
  <div style={props.style}>
    <div
      style={{
        fontSize: 26,
        lineHeight: "26px",
        padding: `0 ${hPadding}px`,
        fontWeight: "bold",
        letterSpacing: 0.25,
        marginBottom: 16,
      }}
    >
      {props.title} {props.icon}
    </div>

    {props.description ? <Paragraph children={props.description} /> : null}

    <ul style={{ marginTop: 10, lineHeight: 1, padding: `0 ${hPadding}px` }}>
      {props.items.map((item, index) => (
        <li
          key={index}
          children={item}
          style={{
            fontSize: 18,
            lineHeight: "22px",
            // color: "#1B203C",
            // fontSize: "0.7rem",
            // opacity: 0.8,
            // letterSpacing: "1px",
            // marginTop: -4,
            // marginBottom: 8,
          }}
        />
      ))}
    </ul>
  </div>
)

const ImageCard = props => (
  <div style={props.style}>
    <img
      src={props.src}
      style={{
        width: "100%",

        borderRadius: 20,
        boxShadow:
          "-24px 18px 36px -8px rgba(24, 74, 123, 0.12), inset 0px 0px 36px rgba(255, 255, 255, 0.36)",
      }}
    />
    {props.description ? (
      <p
        children={props.description}
        style={{
          marginTop: 0,
          padding: `0 ${hPadding}px`,
          fontSize: 16,
          letterSpacing: 0.4,
          lineHeight: "19px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      />
    ) : null}
  </div>
)

const PositionCard = props => {
  const target = props.position
  const isClickable = false

  const comp = (
    <Tilt
      className="Tilt"
      options={{ max: 10, reverse: true }}
      style={{
        // padding: hPadding,
        // paddingTop: hPadding * 2,
        marginBottom: 20,
        borderRadius: 20,
        backgroundColor: "white",
        // textAlign: "center",
        transformStyle: "preserve-3d",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
        transform:
          "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
      }}
    >
      <Grid
        style={{
          position: "relative",
          opacity: target.closed ? 0.4 : 1,
          // padding: hPadding,
          // paddingTop: hPadding * 2,
          // marginBottom: 23,
          padding: hPadding * 2,
          // paddingBottom: 0,

          // background: "#FFFFFF",
          // boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
          // borderRadius: 20,

          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            textTransform: "uppercase",
            opacity: 0.3,
            marginBottom: 8,
            fontSize: 15,
            fontWeight: "bold",
          }}
          // children={target.area}
          children="Posizione aperta"
        />

        <h2
          children={(target.roleH1 || target.role).replace("_", " ")}
          style={{
            // color: "#1B203C",
            backgroundColor: "#e85322",
            background: `radial-gradient(
              100% 500% at 80% 0%,
              #f49622 30%,
              #e5142c 100%
            )`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        />
        {target.competences.map((item, index) => (
          <span key={index}>
            <b
              children={item.title}
              style={{ letterSpacing: 1, color: "#1B203C" }}
            />
            <ul style={{ marginTop: 10, lineHeight: 1 }}>
              {item.skills.map((skill, i) => {
                if (skill === "OR") {
                  return (
                    <div
                      key={index + "_" + i}
                      children="(oppure)"
                      style={{
                        color: "#1B203C",
                        fontSize: "0.7rem",
                        opacity: 0.8,
                        letterSpacing: "1px",
                        marginTop: -4,
                        marginBottom: 8,
                      }}
                    />
                  )
                }

                return <li children={skill} key={i} />
              })}
            </ul>
          </span>
        ))}

        {target.moreInfo ? (
          <div
            children={target.moreInfo}
            style={{ marginTop: 0, marginBottom: 12, opacity: 0.8 }}
          />
        ) : null}

        <a
          href={props.href || createTargetMailto(target)}
          style={{ alignSelf: "center" }}
        >
          <div
            className="Tilt-inner btn-candidati"
            children={props.cta || target.ctaBtn}
            style={{ alignSelf: "center" }}
          />
        </a>

        {/* <div
          style={{
            display: "inline-block",
            fontWeight: "bold",
            fontSize: 20,
            color: target.closed ? "#333" : "#e85322",
            padding: 12,
            margin: -12,
            // background: `radial-gradient(
            //   100% 500% at 80% 0%,
            //   #f49622 30%,
            //   #e5142c 100%
            // )`,
            // WebkitBackgroundClip: "text",
            // WebkitTextFillColor: "transparent",
          }}
          children={target.closed ? "Non disponibile" : "Scopri di più"}
        /> */}
        {/* <ArrowForwardIcon /> */}
      </Grid>
    </Tilt>
  )

  if (isClickable) return <a href={"/" + target.slug} children={comp} />

  return comp
}
